
const state = () => ({
    connectionCode:'',
    connectionState:'Not Connected',
    webSocketConnetion: null,
    resend: false,
    customerDisplayDeviceConnected: false,
});

// getters
const getters = {
    connectionCode :  state => state.connectionCode,
    connectionState : state => state.connectionState,
    resend: state => state.resend,
    isConnected: (state) => state.customerDisplayDeviceConnected,
};

// mutations
const mutations = {
    SET_CONNECTION_CODE: (state, connectionCode) => state.connectionCode = connectionCode,
    SET_CONNECTION_STATE: (state,connectionState) => state.connectionState = connectionState,
    SET_RESEND_CONNECTION_KEY_STATUS: (state, status) => { state.resend = status },
    SET_CUSTOMER_DISPLAY_DEVICE_CONNECTED(state, status) { state.customerDisplayDeviceConnected = status },
};

// actions
const actions = {
    setConnectionCode: ({commit}, connectionCode)=> commit('SET_CONNECTION_CODE',connectionCode),
    setConnectionState: ({commit},connectionState) => commit('SET_CONNECTION_STATE', connectionState),
    sendMessage: ({state},message) => {
        if (state.webSocketConnetion)
          state.webSocketConnetion.send(message)

    },
    setResendConnection: ({commit}, status) => { commit('SET_RESEND_CONNECTION_KEY_STATUS', status); console.log("RESEINFIN", status) },
    updateCustomerDisplayConnection: ({ commit }, status) => { commit('SET_CUSTOMER_DISPLAY_DEVICE_CONNECTED', status) },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
