
import DashboardService from "../../services/dashboard-service";
import { ORDER_STATUS } from "@/helpers/order/status";
import indexDbService from "@/helpers/dbHelper"
import * as Sentry from "@sentry/vue";

// const soundNewOrder = new Audio(require("../../assets/sounds/online_order_notificaiton.wav"));

const removeOlderThenDays = 5;

const state = () => ({
  orders: [],
  updating_order: {
    local_id: null,
    remote_id: null
  },
  refundItems: [],
  refundOrderId: null,
  deductRefundQuantity: true,
  refundedOrderTableId:null,
  orderList: [],
  refundedOrdersList: [],
  pagination: 1,
  isLoad: false,
  emptyCurrentOrder: false,
  syncedOrderList: [],
  isActiveOrder:false,
  refundedOrderDiscountTotalDetails: {
    value: 0,
    dsicountType:0
  },
  tableOrders:[],
  orderInitialSync:false,
  wholeOrderDiscount:{
    value:0,

  },
  selectedOrder:null,
  updatedGiftCardPayments:[]
});

// getters
const getters = {
  getUpdatingOrder: (state) => state.updating_order,
  getOrderByIdOrLocalId: (state) => (id) => state.orders.find(order => (order.id && order.id === id) || (order.local_id && order.local_id === id)) || state.orderList.find(order => (order.id && order.id === id) || (order.local_id && order.local_id === id)),
  getOrderLocalIdOrById: (state) => (id) => state.orderList.find(order => (order.id && order.id === id) || (order.local_id && order.local_id === id)) || state.orders.find(order => (order.id && order.id === id) || (order.local_id && order.local_id === id)),
  getAllOrders: (state) => state.orders,
  getOrdersByStatus: (state) => (status) => state.orders.filter(o => o.status === status),
  getAllActiveOrders: (state) => state.orders.filter(o => o.status === ORDER_STATUS.OPEN || o.status === ORDER_STATUS.HOLD),
  refundItems: (state) => state.refundItems,
  refundOrderId: state => state.refundOrderId,
  shouldDeductQuantity: state => state.deductRefundQuantity,
  refundedOrderTableId: state => state.refundedOrderTableId,
  orderList: state => state.orderList,
  refundedOrdersList: state => state.refundedOrdersList,
  isLoad: state => state.isLoad,
  emptyCurrentOrder: state => state.emptyCurrentOrder,
  isActiveOrder: state => state.isActiveOrder,
  refundedOrderDiscountTotalDetails: state => state.refundedOrderDiscountTotalDetails,
  tableOrders:state=> state.tableOrders,
  orderInitailSync : state => state.orderInitialSync,
  wholeOrderDiscount: state => state.wholeOrderDiscount,
  updatingGiftCardPayments: state => state.updatedGiftCardPayments,
  showSelectedOrder: state => state.selectedOrder
};

// mutations
const mutations = {
  removeOldOrders (state) {
    const dateInPast = new Date();
    dateInPast.setDate(dateInPast.getDate() - removeOlderThenDays);
    state.orders = state.orders.filter(o => {
      return !(((new Date(o.updated_at)) < dateInPast) && o.status === ORDER_STATUS.COMPLETED);
    });
  },
  async updateOrders(state, payload) {
    const now = (new Date).toISOString();
    if (!Array.isArray(payload))
      payload = [{...payload}]

      await payload.forEach(async retrievedOrder => {
      const orderIndex = state.orders.findIndex(order => (retrievedOrder.id && (order.id === retrievedOrder.id) ));
        if (orderIndex === -1) {
        state.orders.push({ ...retrievedOrder, ...{ synced_at: now, updated_at: now, total_sum: retrievedOrder.grand_total } });
        indexDbService.saveToDb('orders',state.orders[state.orders.length-1])
        // play song if new online
        // if (retrievedOrder.type === 1) {
        //   soundNewOrder.play();
        // }

      } else {
        const retrievedUpdateDate = retrievedOrder.updated_at ? new Date(retrievedOrder.updated_at).getTime() : null;
        const localUpdateDate = state.orders[orderIndex].updated_at ? new Date(state.orders[orderIndex].updated_at).getTime() : null;
          if ((retrievedUpdateDate >= localUpdateDate) || !localUpdateDate) {
            // if(state.orders[orderIndex].status <= retrievedOrder.status && (state.orders[orderIndex].status === ORDER_STATUS.OPEN || state.orders[orderIndex].status === ORDER_STATUS.HOLD)  )
              state.orders[orderIndex] = { ...state.orders[orderIndex], ...retrievedOrder, ...{ total_sum: retrievedOrder.grand_total } };
          state.orders = [...state.orders]
        } else if (retrievedUpdateDate < localUpdateDate) {
            if (state.orders[orderIndex].status !== 5 || state.orders[orderIndex].status !== 3 || state.orders[orderIndex].status !== 4) {
              state.orders[orderIndex] = { ...state.orders[orderIndex], ...retrievedOrder, ...{ total_sum: retrievedOrder.grand_total } };
              state.orders = [...state.orders]
            }
        }
          indexDbService.saveToDb("orders",state.orders[orderIndex])
        }
      });
  },
  async saveOrder (state, payload) {
    console.log("saveOrder", payload);
    console.log("JSON",JSON.stringify(payload))
    const now = (new Date).toISOString();
    payload = {
      ...payload, ...{
        created_at: payload.id ? payload.created_at : now,
        updated_at: now,
        updated: true
      }
    };
    const existingOrderIndex = state.orders.findIndex(order => (payload.id && order.id === payload.id) || (payload.local_id && (order.local_id === payload.local_id)));
    const foundRefundOrder = state.refundedOrdersList.findIndex(refundedOrder=>refundedOrder.local_id === payload.local_id)
    console.log("existingOrderIndex", existingOrderIndex);
    if (existingOrderIndex === -1) {
      console.log("existingOrderIndex push");
      state.orders.push(payload);
      // // updating refund order list as well
      if (payload.grand_total < 0.00) {
        if (foundRefundOrder === -1) {
          state.refundedOrdersList.push(payload)
        }
          indexDbService.saveToDb('refundedOrderList', payload)
      }
         indexDbService.saveToDb('orders',payload)
    } else {
      console.log("existingOrderIndex push else");
      const oldStatus = state.orders[existingOrderIndex].status;
      if ((oldStatus === ORDER_STATUS.COMPLETED || oldStatus === ORDER_STATUS.CANCELLED || oldStatus === ORDER_STATUS.REFUNDED) && oldStatus !== payload.status) {
        console.error(`You can not update status of orders with status Completed or Canceled, status: ${state.orders[existingOrderIndex].status}, not saved...`);
        return;
      }
      state.orders = [...state.orders.map((item, itemIndex) => itemIndex !== existingOrderIndex ? item : { ...item, ...payload })];
      state.syncedOrderList.push(payload)
      if (payload.grand_total < 0.00) {
        state.refundedOrdersList[foundRefundOrder] = { ...state.refundedOrdersList[foundRefundOrder], ...payload }
        state.refundedOrderList = [...state.refundedOrdersList]
      }
      state.orders = [...state.orders]
      indexDbService.saveToDb('orders', state.orders[existingOrderIndex])
      console.log("UPDATING ORDER IN INDEX DB", payload);
    }
  },
  async updateOrderId (state, payload) {
    if (!payload.local_id) return;
    state.updating_order = {
      local_id: payload.local_id,
      remote_id: payload.id
    };
    const objIndex = state.orders.findIndex((obj => obj.local_id === payload.local_id));
    state.orders[objIndex].id = payload.id;
    await indexDbService.saveToDb('orders',state.orders[objIndex])
  },

  // async updateGiftPaymentId(state, {updatedOrder, index}){
  //     if(updatedOrder.order_gift_card_payment.length > 0){
  //       state.orders[index].order_gift_card_payment = updatedOrder.order_gift_card_payment 
  //       state.updatedGiftCardPayments = updatedOrder.order_gift_card_payment 
  //     }  
      
  // },
  
  updateLocalIds (state, context) {
    const ordersWithLocalIds = state.orders.filter(o => {
      return (o.customer && o.customer.toString().includes("_")) || (o.shift && o.shift.toString().includes("_"));
    });
    ordersWithLocalIds.forEach((o, oIndex) => {
      if (o.customer.toString().includes("_")) {
        const customer = context.rootState.getters["customers/getAllCustomers"].find(c => c.local_id === o.customer);
        if (customer && customer.id) {
          state.orders[oIndex].customer = customer.id;
        } else {
          console.warn("Could not find customer to replace local_id to id");
        }
      }
      if (o.shift.toString().includes("_")) {
        const shift = context.rootState.getters["shifts/getAllShifts"].find(s => s.local_id === o.shift);
        if (shift && shift.id) {
          state.orders[oIndex].shift = shift.id;
        }
      }
    });
  },
  setRefundItems(state, refundItems) {
    state.refundItems = refundItems;
  },
  setRefundOrderId(state, refundId) {
    state.refundOrderId = refundId;
  },
  setDeductRefundQuantity(state, isMore) {
    state.deductRefundQuantity = isMore
  },
  setRefundOrderTableId(state, tableId) {
    state.refundedOrderTableId= tableId
   },
  fetchOrderListByStatus(state, orderList) {
    const removeDuplicateOrders = orderList.filter(order => {
      if (state.orderList.findIndex(exsistingOrder => (exsistingOrder.id === order.id)) === -1)
        return order
      else {
        let index = state.orderList.findIndex(exsistingOrder => (exsistingOrder.id === order.id))
        state.orderList[index] = order
      }
    })
    state.orderList.push(...removeDuplicateOrders)
    indexDbService.saveToDb('orders',removeDuplicateOrders)
  },
  fetchRefundedOrderList(state, refundedOrderList) {
    const removeDuplicateOrders = refundedOrderList.filter(order => {
      if (state.refundedOrdersList.findIndex(exsistingOrder => (exsistingOrder.id === order.id)) === -1)
        return order
      else {
        let index = state.refundedOrdersList.findIndex(exsistingOrder => (exsistingOrder.id === order.id))
        state.refundedOrdersList[index] = order
        indexDbService.saveToDb('refundedOrderList',state.refundedOrdersList[index])
      }
    })
    state.refundedOrdersList.push(...removeDuplicateOrders)
    indexDbService.saveToDb('refundedOrderList',removeDuplicateOrders)
  },
  emptyOrderList(state) {
    state.orderList = []
    state.refundedOrdersList = []
    state.orders = []
  },
  updateOrderList(state, order) {
    state.orderList.push(...order)
  },
  setEmptyCurrentOrder(state, isEmptyCurrentOrder) {
    state.emptyCurrentOrder = isEmptyCurrentOrder
  },
  setSyncedOrder: (state, syncedOrder) => state.syncedOrderList.push(syncedOrder),
  setActiveOrderState: (state, isActiveOrder) => state.isActiveOrder = isActiveOrder,
  setRefundedOrderTotalDiscount: (state, refundedOrderDiscountTotalDetails) => state.refundedOrderDiscountTotalDetails = refundedOrderDiscountTotalDetails,
  setTableOrders:(state,tableOrders)=>state.tableOrders  = tableOrders,
  setDbOrders: (state, orders) => state.orderList = orders,
  setOrderInitailSync : (state,initialSync) => state.orderInitialSync = initialSync,
  setWholeOrderDiscount : (state,discount) => state.wholeOrderDiscount.value = discount,
  setSelectedOrder: (state, orderId) => {state.selectedOrder = orderId}
  // setOrders: async (state, orders) => {
  //   const now = (new Date).toISOString();
  //   if (Array.isArray(orders)) {
  //     orders.forEach(order => {
  //       if (state.orders.findIndex(exsistingOrder => (exsistingOrder.id === order.id)) === -1) {

  //          state.orders.push({ ...order, ...{ synced_at: now, updated_at: now } })
  //        }
  //        else {
  //         let index = state.orders.findIndex(exsistingOrder => (exsistingOrder.id === order.id) || (exsistingOrder.local_id === order.local_id))
  //            state.orders[index] = { ...order }
  //       }
  //     })
  //     state.orders.forEach(order => indexDbService.saveToDb('orders', order))
  //   }
  //   else {
  //     orders = [{ ...orders }]
  //     orders.forEach(order => {
  //       if (state.orders.findIndex(exsistingOrder => (exsistingOrder.id === order.id) ) === -1){
  //         state.orders.push({ ...order, ...{ synced_at: now, updated_at: now } })
  //     }
  //       else {
  //            let index = state.orders.findIndex(exsistingOrder => (exsistingOrder.id === order.id) || (exsistingOrder.local_id === order.local_id))
  //            state.orders[index] = { ...order }
  //       }
  //     })
  //     state.orders.forEach(order => indexDbService.saveToDb('orders', order))
  //   }

  //   //   const removeDuplicateOrders = orders.filter(order => {
  //   //     if (!state.orders.findIndex(exsistingOrder => (exsistingOrder.id === order.id)) === -1)
  //   //       return order
  //   //     else {
  //   //       let index = state.orders.findIndex(exsistingOrder => (exsistingOrder.id === order.id))
  //   //       state.orders[index] = { ...order }
  //   //     }
  //   //   })
  //   //   state.orders.push(...removeDuplicateOrders)
  //   //   // removeDuplicateOrders.forEach(async newOrder => await indexDbService.saveToDb('orders', newOrder))
  //   //   state.orders.forEach(order => indexDbService.saveToDb('orders', order))
  //   //  }
  //   // else {
  //   //   console.log("ORDERSSSS",orders)
  //   //   const orderIndex = state.orders.findIndex(findOrder => findOrder.id === orders.id)
  //   //   if (orderIndex === -1) {
  //   //     state.orders.push({ ...orders })
  //   //     await indexDbService.saveToDb('orders',orders)
  //   //   }
  //   //   else {
  //   //     state.orders[orderIndex] = { ...orders }
  //   //     await indexDbService.saveToDb('orders',state.orders[orderIndex])
  //   //   }
  //   // }
  // }
};

// actions
const actions = {
  fetchOrders: async ({ commit, getters }) => {
    commit("removeOldOrders");
    const activeOrdersIds = getters.getAllActiveOrders.map(o => o.id || o.local_id);
    let retrieved = (await DashboardService.getOrders(activeOrdersIds)).results;
    await commit("updateOrders", retrieved);
  },
  uploadOrder: async ({ state, commit }, order) => {
    if (window.navigator.onLine) {
      const synced_at = (new Date).toISOString();
      const orderIndex = state.orders.findIndex(orderFind => (order.id && (orderFind.id === order.id)) || (order.local_id && (orderFind.local_id === order.local_id)));
      const retrieved = (await DashboardService.postOrder(order));
      state.orders[orderIndex].updated = false;
      state.orders[orderIndex].synced_at = synced_at;
      commit("updateOrderId", retrieved);
    }
  },
  uploadOrders: async ({ state, dispatch }) => {
    const ordersToUpload = state.orders.filter(order => !order.synced_at || ((new Date(order.updated_at)) > (new Date(order.synced_at))));
    for (let instance of ordersToUpload) {
      const syncedOrdersList = await indexDbService.getDbData('orders')
     if (syncedOrdersList.length > 0) {
       let toBeSyncedOrder = syncedOrdersList.filter(order => order.local_id === instance.local_id)
       if ((toBeSyncedOrder.status === 3 || toBeSyncedOrder.status === 5) && instance.status === 1) {
         instance = toBeSyncedOrder
       }
     }

      const orderIndex = state.orders.findIndex(orderFind => (instance.id && (orderFind.id === instance.id)) || (instance.local_id && (orderFind.local_id === instance.local_id)));
      try {
        await dispatch("uploadOrder", instance);
        state.orders[orderIndex].updated = false;
      } catch (e) {
        Sentry.captureException(e);
        console.error(`Could not upload ${instance.local_id}`);
        console.error(e);
      }
    }
  },
  initNewOrder ({ commit, rootGetters }, payload = {}) {
    let newOrder = {
      local_id: `${rootGetters["config/terminal"].id}_${Date.now()}`,
      open_date: new Date().toISOString(),
      subtotal: 0,
      updated_at: new Date().toISOString(),
      items: [],
      tax_total: 0,
      status: 1,
      discount_total: 0,
      discount_orders: 0,
      grand_total: 0,
      number: "0",
      payments: []
    };
    newOrder = { ...newOrder, ...payload };
    commit("saveOrder", newOrder);
    return newOrder;
  },
  setRefundItems({ commit }, refundItems) {
    commit("setRefundItems", refundItems);
  },
  setRefundOrderId({ commit }, refundItem) {
    commit("setRefundOrderId",refundItem);
  },
  setDeductRefundQuantity({ commit }, isMore) {
    commit('setDeductRefundQuantity',isMore)
  },
  setRefundOrderTableId({ commit }, tableId) {
    commit('setRefundOrderTableId', tableId)
  },
  async fetchOrderListByStatus({ commit, state }, orderListDetails) {
    // only fetch data from the server if the application is online
    if (window.navigator.onLine) {
      try {
        let orders = []
        if (state.pagination < 1) {
          state.pagination = 1
        }
        if (orderListDetails.initial) {
          state.pagination = 1
          // state.isLoad = true
          orders = (await DashboardService.getOrderList(orderListDetails.orderStatus, 1)).results
          if (orderListDetails.orderStatus === 3) {
            // added total sum variable
            orders = orders.map(order => {
              order.total_sum = parseFloat((order.grand_total).toFixed(2))
              return order
            })
            const refundedOrders = (await DashboardService.getOrderList(5, 1)).results
            commit('fetchRefundedOrderList', refundedOrders)
            indexDbService.saveToDb("refundedOrderList",refundedOrders)
          }
          state.pagination = state.pagination + 1
          if (orderListDetails.onlineOrders) {
            orders = orders.filter(order => order.type === 1)
            await commit("updateOrders", orders);
          }
          else {
            commit('fetchOrderListByStatus', orders)
            indexDbService.saveToDb("orderList",orders)
          }
          state.isLoad = false

        }
        else {
          state.isLoad = true
          orders = (await DashboardService.getOrderList(orderListDetails.orderStatus, state.pagination)).results
          if (orderListDetails.orderStatus === 3) {
            try {
              const refundedOrders = (await DashboardService.getOrderList(5, state.pagination)).results
              commit('fetchRefundedOrderList', refundedOrders)
            }
            catch (err) {
              Sentry.captureException(err);
              console.log(err)
             }
          }
          state.pagination += 1
          commit('fetchOrderListByStatus', orders)
          await commit("updateOrders", orders);
          indexDbService.saveToDb("orderList", orders)
          indexDbService.saveToDb("refundedOrderList",orders)
          state.isLoad = false
        }
      }
      catch (e) {
        Sentry.captureException(e);
        state.isLoad = false
      }
    }
  },
  emptyOrderList({ commit }) {
    commit('emptyOrderList')
  },
  async fetchOrderById(_,orderId) {
    const order = (await DashboardService.getOrder(orderId)).results
    return order
  },
  setEmptyCurrentOrder({ commit }, isEmptyCurrentOrder) {
    commit('setEmptyCurrentOrder',isEmptyCurrentOrder)
  },
  updateLocalIds({ commit }) {
    commit('updateLocalIds')
  },
  setSyncedOrderStatus: ({ commit }, syncedOrderStatus) => commit('setSyncedOrderStatus', syncedOrderStatus),
  setSyncedOrder: ({ commit }, syncedOrder) => commit('setSyncedOrder', syncedOrder),
  setActiveOrderState: ({commit},isActiveOrder) => commit('setActiveOrderState',isActiveOrder),
  setRefundedOrderTotalDiscount: ({ commit }, refundedOrderDiscountTotalDetails) => commit('setRefundedOrderTotalDiscount', refundedOrderDiscountTotalDetails),
  fetchAllRefundedItems: async ({ commit }) => {
    const allRefundedItems = (await DashboardService.getAllRefundedOrders()).results
    commit('fetchRefundedOrderList', allRefundedItems)
  },
  fetchOnlineOrders: async ({ commit }, orderStatus) => {
    if (window.navigator.onLine) {
      const orders = (await DashboardService.getOnlineOrdersList(orderStatus)).results
      await commit("updateOrders", orders);
    }
  },
  fetchOpenOrders: async ({ commit, state }) => {
    if (window.navigator.onLine) {
      const retrievedOrders = (await DashboardService.getOpenOrders()).results
      const newOrderArray = []
      retrievedOrders.forEach(retrievedOrder => {
        const newOrder = state.orders.find(order => (order.id === retrievedOrder.id) && ((order.status > retrievedOrder.status) ) )
        if (!newOrder) {
          retrievedOrder.synced_at = (new Date).toISOString();
          newOrderArray.push(retrievedOrder)
        }
      })
      await commit("updateOrders", newOrderArray);
      commit('fetchOrderListByStatus', newOrderArray)
      // commit("setTableOrders", newOrderArray)
    }
  },
  fetchUpdatedTableOrders: async ({ state, commit }, orderId = null) => {
    if (window.navigator.onLine) {
      const recentOrder = (await DashboardService.getRecentOrders())
      if (orderId) {
        let updatedOrders = recentOrder.find(order => order.id === orderId && order.status !== ORDER_STATUS.OPEN)
        const index = state.orders.findIndex(order => order.id === orderId)
        const secondIndex = state.orderList.findIndex(order => order.id === orderId)
        if (index > -1) {
          updatedOrders = { ...updatedOrders, "synced_at": (new Date).toISOString()}
          await commit("updateOrders", updatedOrders);
        }
        if (secondIndex > -1) {
          state.orderList[secondIndex] = { ...updatedOrders }
        }
      }
      else {
        // await commit("updateOrders", recentOrder);
        recentOrder.forEach(async updatedOrder => {
          if (updatedOrder.status !== ORDER_STATUS.OPEN) {
            const index = state.orders.findIndex(order => order.id === updatedOrder.id)
            const secondIndex = state.orderList.findIndex(order => order.local_id === updatedOrder.local_id)
            if (index > -1) {
              updatedOrder = { ...updatedOrder, "synced_at": (new Date).toISOString()}
              await commit("updateOrders", updatedOrder);
            }
            else if (secondIndex > -1) {
              state.orderList[secondIndex] = { ...updatedOrder }
            }
          }
        })
      }
      
      indexDbService.saveToDb("orders",recentOrder)

      // commit("setTableOrders", recentOrder)
    }
  },
  SetOfflineOrdersToTable: ({ commit, state }) => {
    commit("setTableOrders", state.orders)
  },
  async fetchOrdersFromDb({ commit }) {
    const orders = await indexDbService.getDbData('orders')
    // const refundedOrders =  await indexDbService.getDbData('refundedOrderList')
    await commit("fetchOrderListByStatus", orders);
    await commit("updateOrders", orders);
    await commit('fetchRefundedOrderList', orders.filter(order=>order.status === ORDER_STATUS.REFUNDED))
  },
  async uploadOfflineOrders({ commit , state, dispatch }) { 
    if (window.navigator.onLine) {
      if (state.orders.length === 0) { 
        await dispatch('fetchOrdersFromDb')
      }
      // sync all those order which are not created on the backedn or are edited in the offline mode
      const offlineOrders = state.orders.filter(order => ((!order.id && order.local_id) || (new Date(order.updated_at).getTime() >= new Date(order.synced_at).getTime() && order.updated=== true)))
      if (offlineOrders.length > 0) {
        try {
          const retrievedOrders = (await DashboardService.postOrder(offlineOrders));
          commit('fetchOrderListByStatus',retrievedOrders)
          await retrievedOrders.forEach(async updatedOrder => {
          const orderIndex = state.orders.findIndex(order => (order.id === updatedOrder.id) ||  order.local_id === updatedOrder.local_id)
          state.orders[orderIndex].updated = false
          state.orders[orderIndex].synced_at = (new Date).toISOString();
          commit("updateOrderId", updatedOrder)
          // commit("updateGiftPaymentId", { "updatedOrder":updatedOrder, "index" :orderIndex})
          await commit('updateOrders', updatedOrder)
        })
        }
        catch (err) {
          Sentry.captureException(err);
          console.log(err)
        }


      }

    }
  },
  updateOrder({ commit }, orders) {
    commit('updateOrders',orders)
  },
  setOrderInitailSync({commit},initialSync){
    console.log("ININITIAL",initialSync)
    commit("setOrderInitailSync",initialSync)
  },
  setWholeOrderDiscount: ({commit},discount) => commit('setWholeOrderDiscount',discount),
  setSelectedOrder: ({commit}, orderId) => {commit('setSelectedOrder',orderId)}

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
