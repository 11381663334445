import * as Sentry from "@sentry/vue";
const DB_NAME = 'biyo'
const DB_VERSION = '3'
let DB;
const stores = [
    { name: "orders", key: "local_id" },
    { name: "employee", key: "id" },
    { name: "categories", key: "id" },
    { name: "currency", key: "code" },
    { name: "discounts", key: "id" },
    { name: "products", key: "id" },
    { name: "sections", key: "id" },
    { name: "store", key: "id" },
    { name: "tables", key: "id" },
    { name: "tax", key: "id" },
    { name: "shift", key: "id" },
    { name: "customers", key: "id" },
    { name: "orderList", key: "local_id" },
    { name:"refundedOrderList",key:"local_id"},
    { name:"giftCards", key:"gift_card_number"},
    { name:"giftCardsPayments", key:"local_id"}
]

export default {
    async initialize() {
        return new Promise((resolve, reject) => { 
            if(DB) return resolve(DB)
            let request = window.indexedDB.open(DB_NAME,DB_VERSION)
            request.onerror = e => reject(e)
            request.onsuccess = e => { DB = e.target.result; resolve(DB) }
            request.onupgradeneeded = e => { 
                let db = e.target.result
                stores.forEach(store => { 
                    try {
                    if(db.objectStoreNames.contains(store.name))
                        db.deleteObjectStore(store.name)
                        db.createObjectStore(store.name, { keyPath: store.key })
                    }
                    catch (err) {
                        Sentry.captureException(err);
                        console.log(err)
                    }
                })
            }
        })
    },

    async saveToDb(key, data) {
        let db = await this.initialize();
        return new Promise((resolve) => {
            try {
                let trans = db.transaction([key], 'readwrite');
                trans.oncomplete = () => resolve();
                let store = trans.objectStore(key);
                let keyPath = store.keyPath;
    
                const validateKeyPath = (obj) => {
                    if (!keyPath) return true;
                    if (!obj[keyPath]) {
                        console.error(`Error: Object missing key field '${keyPath}' for store '${key}'`, obj);
                        Sentry.captureMessage(`Missing key field '${keyPath}' in object for store '${key}'`, 'error');
                        return false;
                    }
                    return true;
                };
    
                if (Array.isArray(data)) {
                    data.forEach(item => {
                        if (validateKeyPath(item)) {
                            store.put(item);
                        }
                    });
                } else {
                    if (validateKeyPath(data)) {
                        store.put(data);
                    }
                }
            } catch (err) {
                Sentry.captureException(err);
            }
        });
    },
    
    async getDbData(key) {
        let db = await this.initialize();
        return new Promise(resolve => {
            try {
                let trans = db.transaction([key], 'readonly');
                trans.oncomplete = () => {
                    resolve(dbData);
                };
                let store = trans.objectStore(key);
                let dbData = [];
                store.openCursor().onsuccess = e => {
                    let cursor = e.target.result;
                    if (cursor) {
                        dbData.push(cursor.value)
                        cursor.continue();
                    }
                };
            }
            catch (err) { 
                Sentry.captureException(err);
                console.log(err,key)
            }
		});
    },

    async clearAllData(store = null) { 
        /**
         * @param {Array ,String} [store] - array of index db store names of name of index db store
         * 
         * @example
         *  clearAllData()
         *  clearAllData(["orders","categories"])
         *  clearAllData("orders")
         */
        const db = await this.initialize()
        return new Promise((resolve, reject) => { 
            try {
                if (!store) {
                    stores.forEach(store => { 
                    const trans = db.transaction(store.name, 'readwrite')
                    const requestedStore = trans.objectStore(store.name)
                    requestedStore.clear()
                    trans.oncomplete = () => { resolve() }
                    })
                }
                else if (Array.isArray(store)) {
                    store.forEach(store => { 
                    const trans = db.transaction(store, 'readwrite')
                    const requestedStore = trans.objectStore(store)
                    requestedStore.clear()
                    trans.oncomplete = () => { resolve() }
                    })
                }
                else if (typeof store === "string") {
                    const trans = db.transaction([store], 'readwrite')
                    const requestedStore = trans.objectStore(store)
                    requestedStore.clear()
                    trans.oncomplete = () => { resolve() }
                }
            }
            catch (err) { 
                Sentry.captureException(err);
                reject()
            }
            
        })
    }

}